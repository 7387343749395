import Parallax from "./components/parallax";
import Reveal from "./components/reveal";
import PerlinNoise from "./utils/perlinNoise";
window.perlin = new PerlinNoise();

const Components = {
    parallax: Parallax,
    reveal: Reveal,
};

const subscriptions = [];
window.subscribeToTick = fn => {
    if(subscriptions.indexOf(fn) === -1) {
        subscriptions.push(fn);
    }
}

const tick = () => {
    subscriptions.forEach(fn => fn());
    requestAnimationFrame(tick)
}

[...document.querySelectorAll('[data-component]')].forEach(el => {
    const name = el.dataset.component;
    if(Components[name]) {
        new Components[name](el);
    }
});

subscribeToTick(window.perlin.render);

tick();